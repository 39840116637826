import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 404.000000 316.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,316.000000) scale(0.100000,-0.100000)">
     
<path d="M1787 2424 c-95 -21 -158 -46 -250 -98 -352 -201 -517 -626 -391
-1011 32 -99 53 -140 117 -233 96 -140 243 -253 406 -312 115 -42 200 -53 347
-47 148 6 236 30 364 99 67 36 183 123 218 163 148 172 218 338 229 541 13
245 -63 463 -220 630 -113 121 -223 193 -377 245 -86 29 -108 32 -235 35 -99
3 -160 -1 -208 -12z m378 -74 c279 -72 497 -287 574 -570 29 -103 29 -285 1
-395 -101 -396 -494 -658 -890 -594 -389 62 -681 399 -680 786 1 309 170 577
456 721 158 80 356 99 539 52z"/>
<path d="M1970 2040 l0 -170 74 0 c70 0 76 2 100 30 33 39 41 88 27 157 -20
103 -72 153 -160 153 l-41 0 0 -170z"/>
<path d="M1730 2166 c-195 -81 -345 -256 -385 -451 -19 -91 -19 -225 0 -300
17 -69 73 -186 108 -230 l24 -29 -18 69 c-23 89 -24 233 -3 306 35 121 130
222 268 285 l86 39 0 168 c0 127 -3 167 -12 167 -7 -1 -38 -11 -68 -24z"/>
<path d="M2310 2067 c0 -61 -23 -110 -76 -167 l-46 -49 44 -22 c120 -60 184
-154 176 -257 -9 -124 -84 -214 -210 -253 -57 -18 -81 -19 -275 -13 -117 4
-213 11 -213 15 0 4 17 9 37 11 57 5 63 25 63 220 l0 167 30 16 c16 8 51 16
77 18 l48 2 3 -177 2 -176 46 -23 c79 -38 186 -14 218 50 33 65 25 165 -20
252 -47 93 -122 125 -277 117 -111 -6 -163 -27 -228 -90 -63 -60 -90 -134 -96
-258 -6 -111 6 -182 47 -268 73 -155 203 -236 380 -237 66 0 92 4 113 18 26
17 27 20 27 115 -1 112 -9 130 -66 138 -90 13 -58 19 116 24 102 3 191 3 198
1 25 -7 11 -20 -25 -23 -49 -4 -65 -35 -61 -120 l3 -63 76 75 c127 127 179
242 187 421 8 183 -25 298 -121 425 -26 34 -70 79 -99 101 -81 60 -78 60 -78
10z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
